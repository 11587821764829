<template>
  <div class="fixed inset-0 bg-gray-800 bg-opacity-75 z-50 py-5">
    <div class="bg-white w-1/2 p-5 m-auto rounded">
      <button
          class="bg-red-600 hover:bg-red-500 text-white font-bold py-1 px-2 rounded absolute top-0 right-0 m-2"
          @click="$emit('close')"
      >
        X
      </button>
      <h2 class="font-semibold text-xl mb-4">
        Edit Quote Details
      </h2>
      <div>
        <label class="block mb-2 text-sm font-medium text-gray-900">Quote Name</label>
        <input
            v-model="quoteObj.quote_name"
            type="text"
            class="mb-4 p-2 w-full border rounded"
        >

        <label class="block mb-2 text-sm font-medium text-gray-900">Customer Name</label>
        <input
            v-model="quoteObj.customer_name"
            type="text"
            class="mb-4 p-2 w-full border rounded"
        >

        <label class="block mb-2 text-sm font-medium text-gray-900">Customer Email</label>
        <input
            v-model="quoteObj.customer_email"
            type="text"
            class="mb-4 p-2 w-full border rounded"
        >

        <label class="block mb-2 text-sm font-medium text-gray-900">Customer Phone</label>
        <input
            v-model="quoteObj.customer_phone"
            type="text"
            class="mb-4 p-2 w-full border rounded"
        >

        <button
            class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
            @click="saveQuoteChanges"
        >
          Save Changes
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {useQuoteStore} from "@/stores/QuoteStore";
export default {
  props: ['quoteName'],
  setup() {
    const quoteStore = useQuoteStore();
    const quoteObj = quoteStore.quoteObj;
    return { quoteObj };
  },
  methods: {
    saveQuoteChanges() {
      const quoteStore = useQuoteStore();

      quoteStore.setQuoteObj(this.quoteObj)
      this.$emit('update');
      this.$emit('close');
    }
  }
};
</script>
