<template>
  <div class="flex flex-wrap mb-4">
    <input
        type="text"
        placeholder="Key"
        v-model="item.key"
        class="p-2 border rounded mr-2 w-1/6"
    >
    <input
        type="text"
        placeholder="Value"
        v-model="item.value"
        class="p-2 border rounded mr-2 w-1/6"
    >
    <input
        type="number"
        placeholder="Price"
        v-model="item.price"
        prefix="£"
        class="p-2 border rounded mr-2 w-1/6"
    >

    <label class="inline-flex items-center mt-3 w-1/6">
      <input
          type="checkbox"
          class="form-checkbox h-5 w-5 text-blue-600"
          v-model="item.visible"
      ><span class="ml-2 text-gray-700">Visible In Quote</span>
    </label>

    <button
        type="button"
        class="ml-2 bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 rounded w-1/6"
        @click="$emit('remove', _key)"
    >
      Remove
    </button>
  </div>
</template>

<script>
export default {
  props: ['_key', 'item'],
  emits: ['remove']
};
</script>
