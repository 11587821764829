<template>
  <div class="p-4">
    <div
        v-if="frames.length > 0"
        class="grid grid-cols-3 gap-4"
    >
      <div
          v-for="(frame, index) in frames"
          :key="frame.id"
          class="bg-white p-4 rounded shadow flex flex-col"
      >
        <img
            :src="frame.image"
            alt="Frame"
            class="mb-2"
        >
        <h3 class="font-semibold mb-2">
          Frame #{{ index + 1 }}
        </h3>
        <p class="text-gray-800 mb-4">
          £{{ frame.price }}
        </p>
        <p class="text-gray-800 mb-4">
          Qty: {{ frame.quantity }}
        </p>
        <div class="mt-auto flex justify-between">
          <button
              class="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded flex items-center group"
              @click="$emit('edit', frame)"
          >
            <svg
                class="h-5 w-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
          </button>
          <button
              class="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 rounded flex items-center group"
              @click="$emit('delete', frame)"
          >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-trash"
                viewBox="0 0 16 16"
            >
              <path
                  d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"
              />
              <path
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"
              />
            </svg>
          </button>
          <button
              class="bg-green-500 hover:bg-green-400 text-white font-bold py-2 px-4 rounded flex items-center group"
              @click="$emit('customize', frame)"
          >
            <svg
                class="h-5 w-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.207V18.5H3.793l11.439-11.439z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div
        v-else
        class="h-screen flex items-center justify-center"
    >
      <h1 style="font-size: 5em;color: #d9d9d9;font-weight: bold;">
        This quote is still empty
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  props: ['frames'],
  emits: ['customize']
};
</script>
