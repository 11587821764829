
export function registerPageComponents(app) {
    const requirePage = require.context('./components/backend/pages', true, /page\.vue$/); // Adjust path if needed

    requirePage.keys().forEach(fileName => {
        let folderName = fileName.replace('.', '').split('/')[1]
        const componentName = `pg-${folderName}`; // Extract name and prefix

        // Dynamically import and register the component
        let page = require(`./components/backend/pages/${folderName}/page.vue`).default;
        app.component(componentName, page);
    });
}
