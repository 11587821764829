import { createApp } from 'vue'
import App from './App.vue'
import { registerPageComponents } from './registerBackendComponents';
import '@/styles.scss';
import { createPinia } from 'pinia';
import QuoteBuilderService from '@/services/QuoteBuilderService';
import Toast from "vue-toastification";
import Vue3ConfirmDialog from '@zapadale/vue3-confirm-dialog'

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

console.log("Backend Master Script Loading....")

if (backendConfig !== undefined) {
    let app = createApp(App);
    let quoteBuilderService = new QuoteBuilderService(backendConfig.pageData);

    // Check if backend location div already exists
    let locationDiv = document.querySelector(`#backend-app`);
    let options = {};

    app.use(createPinia());
    app.use(Toast, options);
    app.use(Vue3ConfirmDialog)

    app.provide('page', 'pg-' + backendConfig.page)
    app.provide('settings', backendConfig.pageData)
    app.provide('quoteBuilderService', quoteBuilderService)
    quoteBuilderService.init()

    // If the backend location div doesn't exist, create a new one
    if (!locationDiv) {
        locationDiv = document.createElement('div');
        locationDiv.id = `backend-app`;
        document.body.appendChild(locationDiv);
    }

    registerPageComponents(app)
    app.mount(`#backend-app`);

} else {
    console.log("Loading Finished")
}

