<template>
  <div class="tailwind">
    <component
        :is="page"
    />
  </div>

  <vue3-confirm-dialog />
</template>

<script>
import { inject } from 'vue';

export default {
  name: 'App',
  setup() {
    let page = inject('page');


    return { page };
  }}
</script>

