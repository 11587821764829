<template>
  <div v-if="loading">
    <div class="h-screen flex items-center justify-center">
      <VueSpinnerOrbit size="8em" />
    </div>
  </div>
  <div v-else>
    <TopToolbar
        :quote-name="quoteName"
        :mode="addProductToggle"
        @edit-quote="showEditQuoteModal = true"
        @add-frame="showSidebar = true"
        @email-quote="showEmailModal = true"
        @view-quote="viewQuote"
        @change-mode="changeMode"
    />
    <transition name="slide-fade">
      <Sidebar
          v-if="showSidebar"
          :products="products"
          @close="showSidebar = false"
          @add-product="addProduct"
      />
    </transition>

    <CustomizeModal
        v-if="showCustomizeModal"
        :frame="selectedFrame"
        @close="showCustomizeModal = false"
        @save-frame="saveCustomizations"
    />

    <EditQuoteModal
        v-if="showEditQuoteModal"
        v-model:quoteName="quoteName"
        @close="showEditQuoteModal = false"
        @update="runUpdate"
    />

    <SendQuoteModal
        v-if="showEmailModal"
        @close="showEmailModal = false"
        @sendEmail="sendEmail"
    />
    <div
        v-if="!addProductToggle"
        class="min-h-screen bg-gray-100"
    >
      <FrameGrid
          :frames="frames"
          @customize="customizeFrame"
          @delete="deleteFrame"
          @edit="editItem"
      />
    </div>
    <div v-else>
      <AddProductIframe
          :product="productAdding"
          :edit="editProductToggle"
          :editing-item="productEditing"
          @reload-quote="updateQuote"
      />
    </div>
  </div>
</template>

<script>
import {useQuoteStore} from "@/stores/QuoteStore";
import TopToolbar from './components/TopToolbar.vue';
import Sidebar from './components/Sidebar.vue';
import CustomizeModal from './components/CustomizeModal.vue';
import EditQuoteModal from './components/EditQuoteModal.vue';
import FrameGrid from './components/FrameGrid.vue';
import AddProductIframe from "@/components/backend/pages/quoting/components/AddProductIframe.vue";
import SendQuoteModal from "@/components/backend/pages/quoting/components/SendQuoteModal.vue";
import {VueSpinnerOrbit} from "vue3-spinners";
import { inject } from "vue";
import { useToast } from "vue-toastification";

export default {
  name: "QuotePage",
  components: {AddProductIframe, TopToolbar, Sidebar, CustomizeModal, EditQuoteModal, FrameGrid, VueSpinnerOrbit, SendQuoteModal},
  setup() {
    const store = useQuoteStore();
    return {store};
  },
  data() {
    return {
      addProductToggle: false,
      editProductToggle: false,
      productEditing: {},
      productAdding: {},
      quoteService: {},
      quoteInfo: {}
    }
  },
  mounted() {
    let quoteService = inject('quoteBuilderService')
    let quoteInfo = inject('settings')
    this.quoteService = quoteService;
    this.quoteInfo = quoteInfo

    quoteService.getQuote();
  },
  computed: {
    loading: {
      get() {
        return this.store.loading;
      },
      set(value) {
        this.store.loading = value;
      }
    },
    showSidebar: {
      get() {
        return this.store.showSidebar;
      },
      set(value) {
        this.store.showSidebar = value;
      }
    },
    showEditQuoteModal: {
      get() {
        return this.store.showEditQuoteModal;
      },
      set(value) {
        this.store.showEditQuoteModal = value;
      }
    },
    showCustomizeModal: {
      get() {
        return this.store.showCustomizeModal;
      },
      set(value) {
        this.store.showCustomizeModal = value;
      }
    },
    showEmailModal: {
      get() {
        return this.store.showEmailModal;
      },
      set(value) {
        this.store.showEmailModal = value;
      }
    },
    quoteName: {
      get() {
        return this.store.quoteName;
      },
      set(value) {
        this.store.quoteName = value;
      }
    },
    products() {
      return this.store.products;
    },
    frames() {
      return this.store.frames;
    },
    selectedFrame: {
      get() {
        return this.store.selectedFrame;
      },
      set(value) {
        this.store.selectedFrame = value;
      }
    }
  },
  methods: {
    addProduct(product) {
      this.productAdding = product;
      this.addProductToggle = true;
      this.showSidebar = false;
      this.editProductToggle = false
      this.productEditing = null
    },
    async saveCustomizations(frame) {
      const toast = useToast();
      await this.quoteService.updateQuoteItem(frame)
      toast('Frame Updated')
      this.updateQuote()
    },
    customizeFrame(frame) {
      this.store.customizeFrame(frame);
    },
    changeMode() {
      this.addProductToggle = !this.addProductToggle
    },
    updateQuote() {
      this.quoteService.getQuote()
    },
    editItem(frame) {
      this.editProductToggle = true
      this.addProductToggle = true
      this.productEditing = frame
    },
    deleteFrame(frame) {

      this.$confirm(
          {
            title: 'Delete ' + frame.name + '?',
            message: 'Are you sure?',
            disableKeys: false,
            auth: false,
            button: {
              no: 'Keep',
              yes: 'Delete'
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: confirm => {
              if (confirm) {
                const toast = useToast();
                this.quoteService.deleteQuoteItem(frame.id)
                toast("Product Deleted From Quote");
              }
            }
          }
      )
    },
    runUpdate() {
      const toast = useToast();
      let quoteStore = useQuoteStore()
      this.quoteService.updateQuote(quoteStore.quoteObj)
      toast("Quote updated")
    },
    sendEmail (data) {
      const toast = useToast();
      this.quoteService.emailQuote(data)
      toast("Quote sent via email")

    },
    viewQuote () {
      window.open(process.env.VUE_APP_API_URL + "/quote/full/specification/v2/" + this.quoteService.token, "_blank");
    }
  }
};
</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
</style>
