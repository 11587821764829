<template>
  <div class="fixed inset-0 bg-gray-800 bg-opacity-75 z-50 py-5">
    <div class="bg-white w-1/2 h-3/4 p-5 m-auto rounded overflow-auto">
      <button
          class="bg-red-600 hover:bg-red-500 text-white font-bold py-1 px-2 rounded absolute top-0 right-0 m-2"
          @click="$emit('close')"
      >
        X
      </button>
      <h2 class="font-semibold text-xl mb-4">
        Customize
      </h2>

      <form @submit.prevent="saveCustomizations">
        <CustomizationField
            v-for="(item, index) in frame.custom_attributes"
            :key="index"
            :item="item"
            @remove="removeItem(index)"
        />

        <button
            type="button"
            class="mt-4 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            @click="addItem"
            style="margin-right: 20px"
        >
          Add New Item
        </button>
        <button
            type="submit"
            class="mt-4 bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Save Customizations
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import CustomizationField from './CustomizationField.vue';

export default {
  components: {CustomizationField},
  props: ['frame'],
  emits: ['close', 'save-customizations'],

  methods: {
    addItem() {
      if (this.frame.custom_attributes == null) {
        this.frame.custom_attributes = []
      }

      this.frame.custom_attributes.push({ key: '', value: '', visible: 0, price: 0 });
    },
    removeItem(index) {
      this.frame.custom_attributes.splice(index, 1);
    },
    saveCustomizations() {
      // Mock API call to save customizations
      console.log('Saving customizations:', this.frame.customizations);
      this.$emit('save-frame', this.frame);
      this.$emit('close');
    }
  }
};
</script>
