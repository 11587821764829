import { defineStore } from 'pinia';

export const useQuoteStore = defineStore('quote', {
    state: () => ({
        loading: true,
        showSidebar: false,
        showEditQuoteModal: false,
        showCustomizeModal: false,
        showEmailModal: false,
        quoteName: "Window/Door Quote",
        quoteObj: {},
        products: [],
        frames: [
            { id: 1, image: "https://picsum.photos/200/150", price: 250.00, customizations: [] }
        ],
        selectedFrame: null,
        settings: {},
        branding: {}
    }),
    actions: {
        setLoading (value) {
            this.loading = value
        },
        setProducts(products) {
          this.products = products
        },
        setSettings(settings){
          this.settings = settings
        },
        addProduct(product) {
            this.frames.push({
                id: this.frames.length + 1,
                image: product.image,
                price: 300.00,
                customizations: []
            });
            this.showSidebar = false;
        },
        saveCustomizations(customizations) {
            this.selectedFrame.customizations = customizations;
            this.showCustomizeModal = false;
        },
        customizeFrame(frame) {
            this.selectedFrame = frame;
            this.showCustomizeModal = true;
        },
        setQuoteObj (quote) {
            this.quoteObj = quote
            this.quoteName = quote.quote_name
        },
        setFrames (frames) {
            this.frames = frames
        },
        setBranding (branding) {
            this.branding = branding;
        }
    }
});
