<template>
  <iframe
      :src="iframeUrl"
      style="width: 90vw; height: 90vh"
  />
</template>

<script>
import {inject} from "vue";
import { useToast } from "vue-toastification";
import { useQuoteStore } from "@/stores/QuoteStore";

export default {
  name: "AddProductIframe",
  props: ['product', 'edit', 'editingItem'],
  computed: {
    iframeUrl() {
      let quoteInfo = inject('settings')
      let quoteStore = useQuoteStore();

      if (this.edit) {
        return process.env.VUE_APP_API_URL + '/iframe/edit/product/' + this.editingItem.id + '?sessIdOVR=quote-' + quoteInfo.quoteToken + '&typeOVR=quote&modeOVR=create&security_key=' + quoteStore.quoteObj.security_key

      } else {
        return process.env.VUE_APP_API_URL + '/iframe/product/' + this.product.id + '?sessIdOVR=quote-' + quoteInfo.quoteToken + '&typeOVR=quote&modeOVR=create'
      }
    }
  },
  methods: {
    receiveMessage (event) {
      if (event.data == 'cartUpdated') {
        this.$emit('reload-quote')

        // Get toast interface
        const toast = useToast();

        // Use it!
        toast("Product Added To Quote");
      }
    }
  },
  mounted () {
    window.addEventListener('message', this.receiveMessage)
  },
  beforeUnmount () {
    window.removeEventListener('message', this.receiveMessage)
  }
}
</script>
