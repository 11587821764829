<template>
  <div class="fixed inset-0 bg-gray-800 bg-opacity-75 z-50">
    <div class="bg-white w-80 h-full p-5 overflow-auto">
      <button
          class="bg-red-600 hover:bg-red-500 text-white font-bold py-1 px-2 rounded absolute top-0 right-0 m-2"
          @click="$emit('close')"
      >
        X
      </button>
      <h2 class="font-semibold text-xl mb-4">
        Select Product
      </h2>
      <div class="flex flex-col space-y-4">
        <div
            v-for="product in products"
            :key="product.name"
            class="product-card cursor-pointer p-4 border rounded shadow"
            @click="$emit('add-product', product)"
        >
          <img
              :src="product.image"
              :alt="product.name"
              class="mb-2"
          >
          <h3 class="font-semibold mb-1">
            {{ product.name }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuotingSidebar",
  props: ['products'],
  emits: ['close', 'add-product']
};
</script>
