<template>
  <div class="fixed inset-0 bg-gray-800 bg-opacity-75 z-50 py-5">
    <div class="bg-white w-1/2 p-5 m-auto rounded">
      <button
          class="bg-red-600 hover:bg-red-500 text-white font-bold py-1 px-2 rounded absolute top-0 right-0 m-2"
          @click="$emit('close')"
      >
        X
      </button>
      <h2 class="font-semibold text-xl mb-4">
        Edit Quote Details
      </h2>
      <div>
        <label class="block mb-2 text-sm font-medium text-gray-900">Email</label>
        <input
            v-model="email.email"
            type="text"
            class="mb-4 p-2 w-full border rounded"
        >
        <label class="block mb-2 text-sm font-medium text-gray-900">Message To Show On Quote</label>
        <textarea
            id="message"
            rows="4"
            class="block mb-4 p-2.5 w-full text-sm rounded-lg border focus:ring-blue-500 focus:border-blue-500"
            placeholder=""
            v-model="email.message"
        ></textarea>


        <button
            class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
            @click="sendEmail"
        >
          Send Email
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {useQuoteStore} from "@/stores/QuoteStore";
export default {
  props: ['quoteName'],
  data() {
    return {
      email: {
        email: "",
        message: ""
      }
    }
  },
  mounted() {
    let quoteStore = useQuoteStore();
    this.email.message = quoteStore.branding.default_quote_message
    this.email.email = quoteStore.quoteObj.customer_email
  },
  methods: {
    sendEmail() {
      const quoteStore = useQuoteStore();
      this.$emit('sendEmail', this.email)
      this.$emit('close')
    }
  }
};
</script>
