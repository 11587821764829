import axios from "axios";
import {useQuoteStore} from "@/stores/QuoteStore";
// import { useQuoteStore } from "@/stores/QuoteStore";

export default class QuoteBuilderService {
    constructor(pageData) {
        this.baseUrl = process.env.VUE_APP_API_URL
        this.pageData = pageData
        this.token = pageData.quoteToken
        this.key = pageData.quoteApiToken

        // Create Axios instance with base URL
        this.axios = axios.create({
            baseURL: this.baseUrl,
        });
    }

    async init() {
        let response = await this.axios.get('/api/quote-builder/settings')
        const quoteStore = useQuoteStore();
        let responseData = response.data;
        quoteStore.setProducts(responseData.products)
        quoteStore.setSettings(responseData)
    }

    async getQuote() {
        const quoteStore = useQuoteStore();
        let response = await this.axios.get('/api/quote-builder/find/' + this.token + '/' + this.key)
        quoteStore.setQuoteObj(response.data.quote);
        quoteStore.setFrames(response.data.frames)
        quoteStore.setBranding(response.data.branding)
        quoteStore.setLoading(false)

    }

    async deleteQuoteItem(itemId) {
        let response = await this.axios.get('/api/quote-builder/delete/' + this.token + '/' + this.key + '/' + itemId)
        this.getQuote();
        return true;
    }

    async updateQuote(quote) {
        let response = await this.axios.post('/api/quote-builder/update/' + this.token + '/' + this.key, quote)
    }

    async updateQuoteItem(quoteItem) {
        let response = await this.axios.post('/api/quote-builder/update-item/' + this.token + '/' + this.key, quoteItem)
    }

    async emailQuote(postData) {
        let response = await this.axios.post('/api/quote-builder/email/' + this.token + '/' + this.key, postData)
    }
}